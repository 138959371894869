export const useQualificationFormStore = defineStore("qualification-form", {
  state: () => ({
    step: 1,
  }),
  actions: {
    async createLead(
      formData: Partial<LeadFormSubmission>,
      referralCode: string | null
    ) {
      await $fetch("/api/lead-form", {
        method: "POST",
        body: {
          ...formData,
          referral_code: referralCode,
        },
      });
    },
    setStep(step: number) {
      this.step = step;
    },
  },
});
